<template>
  <div class="container">
    <div class="modal fade" ref="modalSchedule" id="modalSchedule" tabindex="-1" aria-labelledby="modalScheduleLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content" v-if="step == 0">
            <p>Processando...</p>
          </div>
          <div class="modal-content" v-if="step == 1">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="modalScheduleLabel">Marcar horário</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <p>Por favor, preencha os dados abaixo para marcar o horário.</p>
              <p class="text-datahora"><strong><span class="dayWeek">{{ moment(day).format('dddd') }}</span> - {{ moment(day).format('DD/MM à\\s HH:mm') }}</strong><span class="flag">{{getFlagCountry()}}</span></p>
              <p class="text-fuso-horario">{{getFusoName}}</p>
              <p class="text-fuso-horario">O agendamento será feito baseado no seu fuso-horário de referência.</p>
              <p v-if="fee_value">Valor da consulta: {{fee_value}}</p>
              <form>
                <div class="mb-3">
                  <label for="name" class="col-form-label">Seu nome:</label>
                  <input type="text" class="form-control" id="name" required>
                </div>
                <div class="mb-3">
                  <label for="phone" class="col-form-label">Seu telefone:</label>
                  <input type="text" class="form-control" id="phone" required>
                </div>
                <div class="mb-3">
                  <label for="email" class="col-form-label">Seu e-mail:</label>
                  <input type="text" class="form-control" id="email">
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <div class="col-12">
                <p><small>(*) A confirmação depende da aprovação do profissional.</small></p>
              </div>
              <div class="col-12">
              <button type="button" class="btn btn-sm btn-light mx-3" data-bs-dismiss="modal">Voltar</button>
              <button type="button" @click="sendForm" class="btn btn-primary">Confirmar</button>
              </div>
            </div>
          </div>
          <div class="modal-content" v-if="step == 2">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="modalScheduleLabel">Solicitação enviada com sucesso!</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <p>A solicitação de consulta foi agendada com sucesso!</p>
              <p v-if="response.whatsapp">Caso deseje, poderá confirmar a consulta por Whatsapp.<br><a :href="response.whatsapp">(clique aqui)</a></p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-success mx-auto" data-bs-dismiss="modal">OK</button>
            </div>
          </div>
          </div>
      </div>
  </div>
</template>

<script>
import bus from '@/main';
import moment from 'moment';
import { Modal } from 'bootstrap';
import api from '@/services/api';

export default {
  name: 'ModalSchedule',
  props: {
    fee_value: String,
    day: Date,
    timeZone: String,
    display: Boolean
  },
  data() {
    return {
      modal: null,
      step: 1,
      response: null
    }
  },
  methods: {
    getFlagCountry() {
      var countryCode = Intl.DateTimeFormat().resolvedOptions().locale.substr(3,2);
      const codePoints = countryCode
        .toUpperCase()
        .split('')
        .map(char =>  127397 + char.charCodeAt());
      return String.fromCodePoint(...codePoints);
    },
    validateForm() {
      if (document.getElementById('name').value == '') {
        alert('Por favor, informe o seu nome.');
        return false;
      }
      if (document.getElementById('phone').value == '') {
        alert('Por favor, informe o seu telefone.');
        return false;
      }
      if (document.getElementById('email').value == '' || document.getElementById('email').value.indexOf('@') == -1) {
        alert('Por favor, informe o seu e-mail.');
        return false;
      }
      return true;
    },
    sendForm() {
      if (!this.validateForm()) return;
      
      this.step = 0;
      api.post(null, {
        name: document.getElementById('name').value,
        phone: document.getElementById('phone').value,
        email: document.getElementById('email').value,
        date: moment.tz(this.day, this.timeZone).format('YYYY-MM-DD HH:mm'),
        timezone_user: Intl.DateTimeFormat().resolvedOptions().timeZone,
        timezone_profissional: this.timeZone,
      }).then((res) => {
        this.response = res.data;
      });
      setTimeout(() => {
        this.step = 2;
      }, 2000);
    },
    selectHour(day, hour) {
      day.setHours(hour.split(':')[0]);
      day.setMinutes(hour.split(':')[1]);
      alert(day);
    },
    generateTimeList(day, startTime, endTime, interval) {
        var dateBegin = new Date(day);
        dateBegin.setHours(startTime.split(':')[0]);
        dateBegin.setMinutes(startTime.split(':')[1]);
        startTime = dateBegin;

        var dateEnd = new Date(day);
        dateEnd.setHours(endTime.split(':')[0]);
        dateEnd.setMinutes(endTime.split(':')[1]);
        endTime = dateEnd;
        
        const timeList = [];

        const startHour = startTime.getHours();
        const startMinute = startTime.getMinutes();
        const endHour = endTime.getHours();
        const endMinute = endTime.getMinutes();

        let currentHour = startHour;
        let currentMinute = startMinute;

        while (currentHour <= endHour && (currentHour < endHour || currentMinute <= endMinute)) {
            const formattedTime = `${currentHour.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')}`;
            timeList.push(formattedTime);

            currentMinute += interval;
            if (currentMinute >= 60) {
              currentMinute = currentMinute - 60;
            // interval > 60
            if (currentMinute >= 60) {
              currentMinute = currentMinute - 60;
              currentHour++;
            }
            currentHour++;
            }
        }

        return timeList;
        },
    dayName(day) {
      if(!day) return null
      if(day.day() === 0) return 'Domss';
      return moment(day).format('ddd');
    },
    returnDays() {
      let days = [];
      for (let i = 0; i < 7; i++) {
        days.push(this.moment().add(i, 'days')._d);
      }
      return days;
    }
  },
  computed: {
    moment() {
      return moment;
    },
    getFusoName() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
  },
  beforeMount() {
    moment.locale('pt-br');
  },
  mounted() {
    this.modal = new Modal(this.$refs.modalSchedule);
    this.modal.show();
    let m = document.getElementById('modalSchedule');
    m.addEventListener('hidden.bs.modal', function () {
      bus.emit('closeModal');
    });
  }
}
</script>

<style lang="scss">
@import'~bootstrap/dist/css/bootstrap.css';
.modal-header{
  background-color: #CCC;
  font-weight: bold;
}
.dayWeek{
  text-transform: uppercase;
}
small{
  font-size: 11px;
  color:#777;
}
.day {
  background-color: #DDD;
  padding: .3em;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 1em;
  height: 1.7em;
  margin-bottom: .5em;
}
.hour {
  color: #777;
  letter-spacing: 1px;
  border-bottom: 1px solid #DDD;
  background-color:#fff;
  padding: .3em;
  span{
    display: block;
  }
  //transition: .2s all;
  &:hover{
    background-color: #f5f5f5;
    cursor: pointer;
  }

}
.text-fuso-horario{
  font-size: 13px;
  font-style: italic;
}
span.flag{
  margin-left: .5em;
}
p.text-datahora{
  margin:0;
}
</style>
