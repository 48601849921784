<template>
  <TimeTable :timezone="timezone" :intervalMinutes="interval" :startWorkHour="start" :endWorkHour="end" :workDays="workingDaysList" />
  <ModalSchedule v-if="display" @close="display = false" :day="selectedDay" :fee_value="fee" :timeZone="timezone" />
</template>

<script>
import bus from './main';
import TimeTable from './components/TimeTable.vue'
import ModalSchedule from './components/ModalSchedule.vue'

export default {
  name: 'AppSchedule',
  components: {
    TimeTable,
    ModalSchedule
  },
  props: {
    fee: {String, default: null},
    interval: {Number, default: 60},
    start: {String, default: '08:00'},
    end: {String, default: '19:00'},
    workdays: {String, default: '1,2,3,4,5'},
    timezone: {String, default: 'Europe/London'}
  },
  computed: {
    workingDaysList() {
      return this.workdays.split(',').map(d => parseInt(d));
    }
  },
  methods: {
    displayModal(day) {
      this.display = true
      this.selectedDay = day
    }
  },
  data() {
    return {
      display: false,
      selectedDay: null
    }
  },
  mounted() {
    console.log('timezone App', this.timezone);
    bus.on('selectDay', (day) => {
      this.displayModal(day)
    });

    bus.on('closeModal', () => {
      this.display = false;
    });
  }
}
</script>

<style>
#app, .scheduleApp, #appSchedule {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
