<template>
  <div class="container">
    <div class="row">
        <div class="col" v-for="(day, i) in list.days" :key="i">

          <div class="day">
            <span v-if="!isToday(day) && !isTomorrow(day)">{{ moment(day).format('ddd') }} - {{ moment(day).format('DD/MM') }}</span>
            <span v-if="isTomorrow(day)">AMANHÃ</span>
            <span v-if="isToday(day)">HOJE</span>
          </div>
          <div class="noWorkDay" v-if="!workDays.includes(day.getDay())"> -- </div>
          <div v-else-if="list.days.length > 1">
            <div :class="{ 'busy': isBusy(day, h), 'expired': isExpired(day, h) }" class="hour" v-for="h,i in generateTimeList(day, startWorkHour, endWorkHour, intervalMinutes)" :key="i">
               <span @click="selectHour(day, h)">{{ h }}</span>
            </div>
          </div>

        </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import bus from '@/main';
import axios from 'axios';

export default {
  name: 'TimeTable',
  props: {
    workDays: {type: Array, default: () => [1, 2, 3, 4, 5]},
    startWorkHour: {String},
    endWorkHour: {String},
    intervalMinutes: {Number, default: 80},
    timezone: {String, default: 'America/Sao_Paulo'}
  },
  data() {
    return {
        list: {
            days: [],
            busy: []
        },
        timezoneLocal: 'America/Sao_Paulo',
        timezoneDefault: 'America/Sao_Paulo',
        timezoneOffset: 0,
        displayGrid: 4,
        today: new Date()
    }
  },
  methods: {
    getAgenda() {
      axios.get('./?getAgenda=1')
      .then(response => {
        this.list.busy = response.data.datas.map((d) => moment(d.date).tz(this.timezoneLocal).format('YYYY-MM-DD HH:mm:ss'));
        this.list.days = this.returnDays();
        console.log('agenda carregada...');
      });
    },
    selectHour(day, hour) {
      day.setHours(hour.split(':')[0]);
      day.setMinutes(hour.split(':')[1]);
      bus.emit('selectDay', day);
    },
    isExpired(day, hour){
      return moment().format('YYYYMMDDHH') > moment(day).format('YYYYMMDDHH') ? true : false;
    },
    isBusy(day, hour){
      day.setHours(hour.split(':')[0]);
      day.setMinutes(hour.split(':')[1]);
      day.setSeconds(0);
      let date = moment(day).format('YYYY-MM-DD HH:mm:ss');
      return this.list.busy.includes(date);
    },
    convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
    },
    generateTimeList(day, startTime, endTime, interval) {
      var localTimeZone = parseInt(this.timezoneOffset);
      var dateBegin = new Date(day);
      
      dateBegin.setHours(parseInt(startTime.split(':')[0]));
      dateBegin.setMinutes(startTime.split(':')[1]);
      dateBegin = moment(dateBegin).add(localTimeZone, 'hours');
      startTime = dateBegin._d;
      
      var dateEnd = new Date(day);
      dateEnd.setHours(parseInt(endTime.split(':')[0]));
      dateEnd.setMinutes(endTime.split(':')[1]);
      dateEnd = moment(dateEnd).add(localTimeZone, 'hours');
      endTime = dateEnd._d;
      
      
        const timeList = [];
        
        const startHour = startTime.getHours();
        const startMinute = startTime.getMinutes();
        const endHour = endTime.getHours();
        const endMinute = endTime.getMinutes();

        //console.log('startHour',startHour, 'endHour', endHour);

        let currentHour = startHour;
        let currentMinute = startMinute;

        while (currentHour <= endHour && (currentHour < endHour || currentMinute <= endMinute)) {
            const formattedTime = `${currentHour.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')}`;
            timeList.push(formattedTime);

            currentMinute += parseInt(interval);
            if (currentMinute >= 60) {
              currentMinute = currentMinute - 60;
            // interval > 60
            if (currentMinute >= 60) {
              currentMinute = currentMinute - 60;
              currentHour++;
            }
            currentHour++;
            }
        }
        return timeList;
        },
    dayName(day) {
      if(!day) return null
      if(day.day() === 0) return 'Domss';
      return moment(day).format('ddd');
    },
    returnDays() {
      let days = [];
      for (let i = 0; i < 7; i++) {
        days.push(this.moment().add(i, 'days')._d);
      }
      return days;
    },
    isToday(date){
      return this.moment(date).format('ddddmmyyyy') == this.moment(this.today).format('ddddmmyyyy');
    },
    isTomorrow(date){
      return this.moment(date).format('ddddmmyyyy') == this.moment(this.today).add(1, 'day').format('ddddmmyyyy');
    }
  },
  computed: {
    moment() {
      return moment;
    }
  },
  beforeMount() {
    moment.locale('pt-br');
  },
  beforeMounted() {
    this.timezoneLocal = Intl.DateTimeFormat().resolvedOptions().timeZone;
  },
  mounted() {
    //moment.tz.setDefault(this.timezone);
    // diferenca entre timezone local e timezone default (profissional)
    this.timezoneOffset = (moment().utcOffset() - moment.tz(new Date, this.timezone).utcOffset()) / 60;
    this.getAgenda();
    console.log('timezone TimeTable', this.timezone);
    console.log('timezoneOffset TimeTable', this.timezoneOffset);
  }
}
</script>

<style lang="scss">
@import'~bootstrap/dist/css/bootstrap.css';
.day {
  background-color: #DDD;
  padding: .3em;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 1em;
  height: 1.7em;
  margin-bottom: .5em;
}
.hour {
  color: #777;
  letter-spacing: 1px;
  border-bottom: 1px solid #DDD;
  background-color:#fff;
  padding: .3em;
  //transition: .2s all;
  &:hover{
    background-color: #f5f5f5;
    cursor: pointer;
  }
  &.busy{
    text-decoration: line-through;
    color:#777;
    background-color: #f5f5f5;
    pointer-events: none;
  }
  &.expired{
    display: none;
  }

}
</style>
